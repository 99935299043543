$.fn.croperModal = function(opt) {
	 let options = {
	 		crop_max_width : 480,
	 		crop_max_height : 480,
	 		image_max_width:1000,
	 		mime_type:'image/png',
	 		jcrop_api : null,
	 		canvas : null,
	 		context : null,
	 		image : null,
	 		prefsize : null,
	 		imgdiv : null,
	 		aspRatio:1,
	 		field_name:null,
	 		place_field: null,
	 		place_image: null,
      img_action:null,
      userid : null,
      usertype : null,
	 }

    options = $.extend({}, options, opt);


	   // ------- Code for cropping image
  $(this).on("change",function() {
    $("#cmdr_croper_modal").modal('show');
    options.field_name =$(this).attr('name')
    options.place_field = $(this).attr('data-hiddenfield')
    options.place_image =$(this).attr('data-imageplace')
    options.img_action = $(this).attr('data-img-action')
    options.userid = $(this).attr('data-userid')
    options.usertype = $(this).attr('data-usertype')
    loadImage(this);
     $(this).val('');
  });

	

   let  set_model_div = function (){
    	if($("#cmdr_croper_modal").length == 0){ 
		    let html =` 

<style>
#cmdr_croper_modal .modal-dialog{
  position: relative;
  display: table; /* This is important */ 
  overflow-y: auto;    
  overflow-x: auto;
  width: auto;
  min-width: 300px;   
}
</style>
 <div class="modal fade" id="cmdr_croper_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
		        <div class="modal-dialog" role="document">
		          <div class="modal-content">
		            <form id="cmdr_cropLocForm">
		              <div class="modal-header">
		                <h4 class="modal-title pull-left" id="myModalLabel">Crop Image</h4>
		                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
		              </div>
		              <div class="modal-body" id="cmdr_croper_modal_body">
                      <div class="">
                        <div class="">
                          <div id="cmdr_crop_views"></div>
                        </div>
                      </div>
		                  
		              </div>
		              <div class="modal-footer">
		                <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->
		                <button type="submit" id="submit_btn" class="btn btn-primary"><span id="spinner_load"></span> Save Changes</button>
		              </div>
		            </form>
		          </div>
		        </div>
		      </div>`;
		      $("body").append(html);
		    }
  	}

    set_model_div();

	 let compress = function(source_img_obj, quality){
    let  mime_type = options.mime_type
    let maxWidth = options.image_max_width;
    var natW = source_img_obj.naturalWidth;
    var natH = source_img_obj.naturalHeight;
    var ratio = natH / natW;
    if (natW > maxWidth) {
        natW = maxWidth;
        natH = ratio * maxWidth;
    }
    var cvs = document.createElement('canvas');
    cvs.width = natW;
    cvs.height = natH;
    var ctx = cvs.getContext("2d").drawImage(source_img_obj, 0, 0, natW, natH);
    var newImageData = cvs.toDataURL(mime_type, quality/100);
    // var result_image_obj = new Image();
    // result_image_obj.src = newImageData;
    // return result_image_obj.src;
    return newImageData
  }


  let loadImage = function(input) {
     if (input.files && input.files[0]) {
        var reader = new FileReader();
        options.canvas = null;
        reader.onload = function (e) {
          options.image = document.createElement('img');
          options.image.onload = function (e) {
            let imgdiv = compress(options.image, 90);
            options.image.src = imgdiv
            setTimeout(function(){validateImage();},100)
            // setTimeout(function(){affixJcrop();},100)
            // options.image.onload = null
          }
          options.image.src = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
    }
  }


 let  dataURLtoBlob = function(dataURL) {
    let BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      let parts = dataURL.split(',');
      let contentType = parts[0].split(':')[1];
      let raw = decodeURIComponent(parts[1]);
      return new Blob([raw], {
        type: contentType
      });
    }
    let parts = dataURL.split(BASE64_MARKER);
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;
    let uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {
      type: contentType
    });
  }



  let  validateImage = function() {
    if (options.canvas != null) {
      options.image = new Image();
      options.image.onload = affixJcrop;
      options.image.src = options.canvas.toDataURL('image/png');
    } else{
    	affixJcrop();
    }
  }

  let  affixJcrop = function() {
    
    if (options.jcrop_api != null) {
      options.jcrop_api.destroy();
    }
    // if( $("#cmdr_crop_views").length == 0){
    //    $("#cmdr_croper_modal_body").append("<div id=\"cmdr_crop_views\"></div>");
    // }else{
    //   $("#cmdr_crop_views").empty();
    // }
    // $("#cmdr_crop_views").append("<canvas id=\"cmdr_crop_canvas\">");
    // options.canvas = document.getElementById('cmdr_crop_canvas');

    $("#cmdr_crop_views").empty();
    $("#cmdr_crop_views").append("<canvas id=\"cmdr_crop_canvas\">");
    options.canvas = $("#cmdr_crop_canvas")[0];

    options.context = options.canvas.getContext("2d");
    options.canvas.width = options.image.width;
    options.canvas.height = options.image.height;
    options.context.drawImage(options.image, 0, 0);
    canvasCroping();
    clearcanvas();
  
  }

  let  canvasCroping = function(){
    $("#cmdr_crop_canvas").Jcrop({
      onSelect: selectcanvas,
      onRelease: clearcanvas,
      boxWidth: options.crop_max_width,
      boxHeight: options.crop_max_height,
      minSize: [100, 100],
      aspectRatio: options.aspRatio,
      setSelect: [0, 0, 1263, 421]
    }, function() {
      options.jcrop_api = this;
    });
  }

  let  clearcanvas = function() {
    options.prefsize = {
      x: 0,
      y: 0,
      w: options.canvas.width,
      h: options.canvas.height,
    };
  }

  let  selectcanvas = function(coords) {
    options.prefsize = {
      x: Math.round(coords.x),
      y: Math.round(coords.y),
      w: Math.round(coords.w),
      h: Math.round(coords.h)
    };
  }

  let  applyCrop = function() {
    options.canvas.width = options.prefsize.w;
    options.canvas.height = options.prefsize.h;
    options.context.drawImage(options.image, options.prefsize.x, options.prefsize.y, options.prefsize.w, options.prefsize.h, 0, 0, options.canvas.width, options.canvas.height);
    
    validateImage(affixJcrop);
  }


  $("#cmdr_cropLocForm").submit(function(e) {
    e.preventDefault();
    applyCrop();
    let l_blob;
    
    if (options.canvas != null){
      l_blob = dataURLtoBlob(options.canvas.toDataURL('image/png'));
      if(options.field_name!= null && options.place_field!= null){
      	$(options.place_field).find('.hidden_field').remove();
      	$(options.place_field).append('<input type="hidden" name="'+options.field_name+'" value="'+options.image.src+'" class="hidden_field">');
      }
      if(options.place_image!=null){
         $(options.place_image).attr('src', options.canvas.toDataURL('image/png'));
         $(options.place_image).removeClass('hidden');
         $(options.place_image).show();
        }
        if(options.img_action=='direct_upload'){
          var uid = options.userid
          var img64_data = options.image.src
          var lurl = "/users/update_image/"+options.userid 
          var datal ={user: {avatar: img64_data,id:options.userid}}
          if(options.usertype=="company")
          {
          var lurl ="/companies/update_image/"+options.userid

           datal = {company: {avatar: img64_data,id:options.userid}}
          }  

          $.ajax({
            type: "POST",
            data: datal,
            url: lurl, 
          }).done(function(data) {

          });
        }
      // console.log(image.src)
      // if (img_type == 'banner'){
      //   $(".locBannerBox").removeAttr('hidden');
      //   $('.banner_box').find('.banner_picker').removeClass('col-md-12').addClass('col-md-9');
      //   $('.banner_box').append('<input type="hidden" name="location[banner]" value="'+image.src+'">');
      //   $(".bannerpic").attr('src', canvas.toDataURL('image/png'));
      // } else {
      //   $(".locImageBox").removeAttr('hidden');
      //   $('.image_box').find('.loc_image_picker').removeClass('col-md-12').addClass('col-md-9');
      //   $('.image_box').append('<input type="hidden" name="testimonial[image]" value="'+image.src+'">');
      //   // $('.image_box').append("admin[avatar]", l_blob);
      //   $(".imagepic").attr('src', canvas.toDataURL('image/png'));
      // }
    }
    $('#cmdr_croper_modal').modal("hide");
  });
     
    return this;
}