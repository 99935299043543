import $ from "jquery";
window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery-ui/ui/widgets/sortable");
require("theme/ofi.min.js")
require("bootstrap/dist/js/bootstrap.bundle.min.js")
require("theme/bootstrap4-toggle.min.js")
require("theme/jquery.toast.js")
require("theme/validator.min.js")
require("theme/imagesloaded.pkgd.min.js")
require("theme/swiper.min.js")
require("theme/anime.min.js")
require("theme/rellax.min.js")

require("theme/isotope.pkgd.min.js")
// require("theme/packery-mode.pkgd.min.js")

require("theme/jquery.Jcrop.min.js")
require("theme/jquery.croper.js")

require("theme/jquery.fancybox.min.js")
require("theme/themebau.min.js")


Rails.start()
ActiveStorage.start()


$(document).ready(function(){
  //attach csrf token with every ajax
  $.ajaxSetup({
    headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });
  confirm_rails();

});

function confirm_rails(){
  $(document).on('mousedown','a[data-confirm]',function(e){
    let $ = window.$b;
    e.preventDefault()
    let link = window.jQuery(this);
    let message = link.data('confirm');
    let cancel_text = link.data('cancel-text');
    let ok_text = link.data('ok-text');
    let modal = window.jQuery("#modal_confirm");
    modal.find('.modal-body').text(message);

    if (cancel_text != ''){
      modal.find('.modal-footer').find('#modal-cancel').text(cancel_text);
    }
    if (ok_text != ''){
      modal.find('.modal-footer').find('#modal-accept').text(ok_text);
    }
    
    let approve = modal.find('.approve')
    approve.attr('data-method', link.data('method'));
    approve.attr('href', link.attr('href'));
    modal.modal('show')
  });
}